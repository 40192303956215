import { type Ref, forwardRef, type ComponentPropsWithRef, type ReactElement } from 'react'
import clsx from 'clsx'
import FieldLabel from '@components/field-label'
import FieldHelp from '@components/field-help'

export interface TextFieldProps extends ComponentPropsWithRef<'input'> {
  label: string | ReactElement
  hideLabel?: boolean
  helpText?: string | ReactElement
  isError?: boolean
  validationText?: string | ReactElement
  labelClass?: string
  height?: 'sm' | 'md'
  startAdornment?: string | ReactElement
}

const TextField = (
  {
    label,
    helpText,
    hideLabel = false,
    isError = false,
    validationText,
    className,
    labelClass,
    height = 'md',
    startAdornment,
    ...props
  }: TextFieldProps,
  ref: Ref<HTMLInputElement>
) => (
  <label className={clsx('flex flex-col', labelClass)}>
    <FieldLabel
      label={label}
      hideLabel={hideLabel}
      isError={isError}
      disabled={props.disabled}
      required={props.required}
      className={clsx(!helpText && 'mb-2')}
    />
    {helpText && <FieldHelp disabled={props.disabled} helpText={helpText} className="mb-3" />}
    <div className="flex items-center">
      {startAdornment && <div className="absolute ml-4">{startAdornment}</div>}
      <input
        {...props}
        ref={ref}
        className={clsx(
          'appearance-none',
          'text-field',
          'w-full h-15 py-4',
          startAdornment ? 'pl-[38px] pr-5' : 'px-5',
          'border rounded-lg box-border',
          'text-normal font-medium',
          'transition-colors',
          // Using ring instead of outline for safari bug: https://bugs.webkit.org/show_bug.cgi?id=20807
          'focus:ring-1 focus:ring-offset-0',
          'focus:outline focus:outline-0 focus:outline-offset-0',
          'enabled:text-gray-700 disabled:text-black/40 placeholder:text-black/30 placeholder-shown:truncate',
          'enabled:bg-white disabled:bg-gray-100 disabled:opacity-100',
          'disabled:cursor-not-allowed',
          height === 'md' && 'p-4 h-15',
          height === 'sm' && 'px-4 py-2 h-10',
          isError && 'border-danger focus:border-danger focus:ring-danger',
          !isError &&
            'border-gray-200 focus:border-primary focus:ring-primary hover:enabled:border-gray-700 focus:hover:enabled:border-primary',
          className
        )}
      />
    </div>
    {isError && !props.disabled && validationText && (
      <div className="text-danger text-sm mt-2">{validationText}</div>
    )}
  </label>
)

export default forwardRef(TextField)
