/**
 * analytics.tsx
 *
 * This module is used as an abstraction layer for the NextJS app router's
 * client server code-splitting.  Client side event listeners (like onClick)
 * cannot be exist inside server components.
 *
 * The two main components are AnalyticsLink and Analytics.  AnalyticsLink
 * is a polymorphic component that adds tracking to interactive components.
 * Analytics does the same thing but wraps around the item you want to track.
 */
'use client'

import {
  cloneElement,
  type ComponentProps,
  type ElementType,
  type MouseEvent,
  useContext,
  useEffect,
  type ReactElement,
} from 'react'
import {
  searchNamespaceLabelMap,
  type SearchNamespace,
} from '@app/[locale]/(main)/search/_components/search-session'
import useAnalytics, { type SPContext, type SPEvent } from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
  PlaceContext,
  ProviderContext,
} from '@lib/analytics'
import { trackAlgoliaConversionEvent } from '@lib/utilities/algolia-utilities'
import { Link } from '@shc/ui'
import { type SendEventForHits } from 'instantsearch.js/es/lib/utils'

export interface AnalyticsProps {
  view?: boolean
  snowplow?: SnowplowData
  algolia?: AlgoliaData
  children?: ReactElement
}

/**
 * An intermediary wrapper for containing client side side effects
 * for use with the NextJS App Router. This wrapper will reduce
 * the amount of code shipped to the browser.
 *
 * @example
 * // For page view tracking:
 * <Analytics view />
 *
 * // For event tracking:
 * <Analytics
 *   snowplow={{
 *     event: {
 *       name: 'navigation_click',
 *       data: {
 *         navigation_tree: '???',
 *         navigation_subject: link1.name,
 *         navigation_level: 1,
 *         navigation_url: link1.route,
 *       },
 *     },
 *     contexts: [{ name: 'section', data: { section_name: 'header' }}],
 *   }}
 * >
 *   {{ Link Component Here }}
 * </Analytics>
 */
function Analytics({ view, children, snowplow, algolia }: Readonly<AnalyticsProps>) {
  const { trackSnowplow, trackPageView } = useAnalytics()
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)

  const environmentContexts: SPContext[] = [
    pageContext,
    placeContext,
    providerContext,
    blockContext,
    itemContext,
  ].filter((context) => context !== null)

  // GUARD
  if ((view && children) || (view && snowplow) || (view && algolia)) {
    throw new Error('If "view" prop is present, there should be no other props')
  }

  // TRACK PAGE VIEW
  useEffect(() => {
    if (view) {
      trackPageView({ contexts: environmentContexts })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (view || !children) return null

  const defaultSnowplow = setDefaultSnowplowEvent(snowplow)

  // TRACK NON-VIEW EVENTS
  return cloneElement(children, {
    onClick: (mouseEvent: MouseEvent) =>
      handleClickEvent(mouseEvent, defaultSnowplow, environmentContexts, algolia, trackSnowplow),
  })
}

/**
 * Extracts the component text from a mouse event.
 *
 * This function determines the appropriate text to use for tracking purposes based on the anchor element of the mouse event.
 * It prioritizes the `aria-label` attribute if it exists, and falls back to the text content of the anchor element.
 *
 * The resulting text is truncated to 64 characters for Algolia and non-visible ASCII characters are replaced with spaces.
 *
 * @param {MouseEvent} mouseEvent - The mouse event.
 * @returns {string} The component text, Algolia eventName is limited to 64 characters.
 */
const extractComponentText = (mouseEvent: MouseEvent): string | null => {
  const target = mouseEvent.currentTarget
  if (!(target instanceof HTMLAnchorElement) && !(target instanceof HTMLButtonElement)) {
    return null
  }

  let componentText = target.ariaLabel || (target.title ? target.title : target.textContent)

  // Replace non-visible ASCII characters with spaces
  componentText = componentText?.replace(/[^\x20-\x7E]+/g, ' ') ?? ''

  return componentText.slice(0, 64)
}

/**
 * If text is a phone number replace with "Phone Number" for tracking purposes.
 *
 * @param {string} textContent - The text content to filter.
 * @returns {string} A message indicating whether a phone number was clicked or the original text content was clicked.
 */
const filterPhoneNumber = (textContent: string): string => {
  const phoneNumberRegex = /^(\+1\s?)?\b\d{3}[-.]?\d{3}[-.]?\d{4}\b$/
  return phoneNumberRegex.test(textContent) ? 'Phone number' : textContent
}

/**
 * Handles Algolia conversion event.
 * If no objectId is passed, default objectId to the current URL.
 *
 * @param {AlgoliaData} algolia - The Algolia data.
 * @param {MouseEvent} mouseEvent - The mouse event.
 *
 * @see {@link https://www.algolia.com/doc/api-reference/api-methods/converted-object-ids-after-search/}
 */
const handleAlgoliaConversion = (algolia: AlgoliaData, mouseEvent: MouseEvent) => {
  if (algolia?.conversion) {
    const componentText = algolia.conversion.eventName ?? extractComponentText(mouseEvent)
    const eventName = filterPhoneNumber(componentText ?? 'undefined')
    const defaultedObjectId = algolia.conversion.objectId ?? window.location.href

    trackAlgoliaConversionEvent(algolia.conversion.namespace, defaultedObjectId, eventName)
  }
}

/**
 * Handles Algolia search result click events.
 * @param {AlgoliaData} algolia - The Algolia data.
 *
 * @see {@link https://www.algolia.com/doc/guides/sending-events/instantsearch/send-events/#using-instantsearch-widgets}
 */
const handleAlgoliaClick = (algolia: AlgoliaData) => {
  if (algolia?.click) {
    algolia.click.sendEvent(
      'click',
      algolia.click.hit,
      `${searchNamespaceLabelMap[algolia.click.namespace]} result`
    )
  }
}

/**
 * Derive Snowplow component context from mouse event.
 *
 * @param {MouseEvent} mouseEvent - The mouse event.
 * @returns {SPContext[]} The component contexts.
 */
const deriveComponentContext = (mouseEvent: MouseEvent): SPContext[] => {
  const anchorElement = mouseEvent.currentTarget
  const componentText = extractComponentText(mouseEvent)
  const componentUrl = anchorElement instanceof HTMLAnchorElement ? anchorElement.href : undefined

  return [
    {
      name: 'component',
      data: {
        component_text: componentText,
        component_url: componentUrl,
      },
    },
  ]
}

/* Represents the data for Algolia events. */
export type AlgoliaData = {
  /*  Data for Algolia click events. */
  click?: {
    /* The namespace for the Algolia event. */
    namespace: SearchNamespace
    /* Function to send the Algolia event. */
    sendEvent: SendEventForHits
    /* The hit object associated with the Algolia event. */
    hit: any
    /* Optional name for the Algolia event - default derives from text of child component. */
    eventName?: string
  }

  /* Data for Algolia conversion events. */
  conversion?: {
    /* The namespace for the Algolia event. */
    namespace: SearchNamespace
    /* Optional object ID for the Algolia conversion event. */
    objectId?: string
    /* Optional name for the Algolia event - default derives from text of child component. */
    eventName?: string
  }
}

export type SnowplowData = {
  event?: SPEvent
  contexts?: SPContext[]
}

/**
 * Sets default Snowplow event if not provided.
 *
 * @param {SnowplowData} [snowplow] - Optional Snowplow data for tracking events.
 * If not provided, a default event with name 'component_click' and empty data will be set.
 * @returns {SnowplowData} The Snowplow data with defaults set.
 * If the input Snowplow data is undefined, a new object with default event and contexts will be returned.
 */
const setDefaultSnowplowEvent = (snowplow?: SnowplowData): SnowplowData => {
  const defaultEvent: SPEvent = {
    name: 'component_click',
    data: {},
  }
  if (!snowplow) snowplow = { event: defaultEvent, contexts: [] }
  if (!snowplow.event) snowplow.event = defaultEvent
  if (!snowplow.contexts) snowplow.contexts = []
  return snowplow
}

/**
 * Handles click events to track Snowplow and Algolia events.
 *
 * @param {MouseEvent} mouseEvent - The mouse event.
 * @param {SnowplowData} snowplow - Snowplow data for tracking events.
 * @param {SPContext[]} environmentContexts - The environment contexts (e.g. page, place, provider, block, item).
 * @param {AlgoliaData} [algolia] - Optional Algolia data for tracking events.
 * @param {Function} trackSnowplow - The Snowplow tracking function.
 * @param {Function} onClick - The onClick handler.
 */
const handleClickEvent = (
  mouseEvent: MouseEvent,
  snowplow: SnowplowData,
  environmentContexts: SPContext[],
  algolia?: AlgoliaData,
  trackSnowplow?: Function,
  onClick?: Function
) => {
  // if snowplow data doesn't have a component context, derive one from the mouse event
  if (snowplow.event?.name === 'component_click') {
    const hasComponentContext = snowplow.contexts?.some((context) => context.name === 'component')

    if (!hasComponentContext) {
      // Derive component context from mouse event and add it to the snowplow data
      const derivedContext = deriveComponentContext(mouseEvent)
      snowplow.contexts = [...(snowplow.contexts ?? []), ...derivedContext]
    }
  }

  // add environment contexts to snowplow contexts
  snowplow.contexts = [...(snowplow.contexts ?? []), ...environmentContexts]

  trackSnowplow?.(snowplow)

  if (algolia) {
    handleAlgoliaClick(algolia)
    handleAlgoliaConversion(algolia, mouseEvent)
  }

  // call the original onClick handler
  onClick?.(mouseEvent)
}

/**
 * Props for the AnalyticsLink component.
 *
 * @template E - The type of the element or component to render.
 * @typedef {Object} AnalyticsLinkProps
 * @property {E} [as] - The component type to render as the root element. CLIENT-SIDE ONLY - CANNOT RENDER SERVER-SIDE COMPONENTS
 * @property {ElementType} [asPassthru] - The component type to pass through to the root element.
 * @property {SnowplowData} [snowplow] - Optional Snowplow data for tracking events.
 * @property {AlgoliaData} [algolia] - Optional Algolia data for tracking events.
 */
type AnalyticsLinkProps<E extends ElementType> = Omit<ComponentProps<E>, 'as'> & {
  as?: E
  asPassthru?: ElementType
  snowplow?: SnowplowData
  algolia?: AlgoliaData
}

/**
 * AnalyticsLink component that tracks Snowplow and Algolia events.
 *
 * @template E - The type of the element or component to render.
 * @param {AnalyticsLinkProps<E>} props - The component props.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * // Basic example
 * // NOTE:
 *      - Since no Snowplow event is sent, defaults to a component_click,
 *      - Uses child text to derive Snowplow component_text
 * <AnalyticsLink as={Button} href="https://example.com">
 *   Click me
 * </AnalyticsLink>
 *
 * @example
 * // Basic example with an image
 * // NOTE: Uses aria-label to derive the Snowplow component_text for non-text children
 * <AnalyticsLink
 *   href="https://example.com"
 *   aria-label="View article">
 *   <img src="path/to/image.jpg" alt="My Article Image" />
 * </AnalyticsLink>*
 *
 * @example
 * // Example search result click
 * <AnalyticsLink
 *   asPassthru={NextLink}
 *   href={link}
 *   noUnderline
 *   className="hover:underline"
 *   target={!slug && placeUrl ? '_blank' : undefined}
 *   snowplow={{ contexts: searchContexts }}
 *   algolia={{ click: { namespace, hit, sendEvent } }}>
 *   {name}
 * </AnalyticsLink>
 *
 * @example
 * // Example conversion click
 * <AnalyticsLink
 *   asPassthru={NextLink}
 *   href={link}
 *   noUnderline
 *   className="hover:underline"
 *   target="_blank"
 *   algolia={{ conversion: { namespace, objectId } }}}>
 *   Book appointment
 * </AnalyticsLink>
 *
 */
const AnalyticsLink = <E extends ElementType>({
  as,
  asPassthru,
  snowplow,
  algolia,
  ...props
}: AnalyticsLinkProps<E>): JSX.Element => {
  const { trackSnowplow } = useAnalytics()
  const Component = as ?? Link
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)

  const environmentContexts: SPContext[] = [
    pageContext,
    placeContext,
    providerContext,
    blockContext,
    itemContext,
  ].filter((context) => context !== null)

  // if snowplow data is incomplete, set default values
  const defaultSnowplow: SnowplowData = setDefaultSnowplowEvent(snowplow)

  const handleClick = (mouseEvent: MouseEvent) => {
    handleClickEvent(
      mouseEvent,
      defaultSnowplow,
      environmentContexts,
      algolia,
      trackSnowplow,
      props.onClick
    )
  }

  return <Component as={asPassthru} {...props} onClick={handleClick} />
}

AnalyticsLink.displayName = 'AnalyticsLink'

export default Analytics
export { AnalyticsLink }
