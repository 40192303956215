import { Fragment, type ComponentPropsWithoutRef, type Dispatch, type SetStateAction } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Backdrop from '@components/backdrop'
import useAnalytics from '@hooks/use-analytics'
import { Button, Icon } from '@shc/ui'

interface ModalProps extends ComponentPropsWithoutRef<'div'> {
  size?: 'sm' | 'md'
  isOpen: boolean
  dismissable?: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  initialFocus?: React.MutableRefObject<HTMLElement | null>
  contentName?: string
  showCloseButton?: boolean
}

const Modal = ({
  size = 'md',
  isOpen,
  setIsOpen,
  dismissable = true,
  initialFocus,
  contentName = '',
  showCloseButton = true,
  className,
  children,
  ...props
}: ModalProps) => {
  const { trackSnowplow } = useAnalytics()
  const onClose = () => {
    if (!dismissable) return
    setIsOpen(false)
    trackSnowplow({
      event: { name: 'modal_close', data: {} },
      contexts: [{ name: 'modal', data: { modal_name: contentName } }],
    })
  }
  return (
    <Transition show={isOpen} as={Fragment} appear={true}>
      <Dialog initialFocus={initialFocus} static={true} onClose={onClose} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          afterLeave={() => setIsOpen(false)}
          enter="transition-all ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all ease-in duration-200 delay-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Backdrop className="fixed inset-0" />
        </Transition.Child>

        {/* Full-screen container to center the panel */}
        <Transition.Child
          as={Fragment}
          afterLeave={() => setIsOpen(false)}
          enter="transition-all ease-out duration-300 delay-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition-all ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="fixed inset-0 flex items-start justify-center p-5 mx-auto overflow-auto">
            <div className="flex min-h-full items-center justify-center">
              <Dialog.Panel
                className={clsx(
                  'relative mx-auto px-5 md:px-10 py-10 rounded bg-white drop-shadow-xl min-w-[335px] md:min-w-[375px]',
                  size === 'sm' && 'max-w-[420px]',
                  size === 'md' && 'max-w-screen-sm'
                )}>
                {showCloseButton && (
                  <div className="absolute right-0 top-0 m-3">
                    <Button
                      color="transparent"
                      size="sm"
                      shape="circle"
                      width="auto"
                      aria-label="Close modal"
                      onClick={onClose}>
                      <Icon icon="xmark-large" className="h-4" />
                    </Button>
                  </div>
                )}
                <div className={className} {...props}>
                  {children}
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

Modal.Title = Dialog.Title
Modal.Description = Dialog.Description

export default Modal
