// chat-analytics-tracker.tsx

'use client'

import { useAppConfig } from '@components/client-wrapper'
import useAnalytics, { type ContactUsSubmitEvent, type SPContext } from '@hooks/use-analytics'
import { useEffect, useRef } from 'react'

export default function ChatAnalyticsTracker() {
  const config = useAppConfig()
  const { trackSnowplow } = useAnalytics()
  const hasStartedChatRef = useRef(false)
  const facetKeys = [
    'appointment_options',
    'features_facet',
    'gender',
    'hospital_affiliations_facet',
    'insurances_facet',
    'interests_facet',
    'languages_facet',
    'medical_groups_facet',
    'place_type_facet',
    'provider_type',
    'services_facet',
    'specialties_facet',
  ]

  const buildChatSearchContext = () => {
    // pull search data from DOM since we don't have access to the "results" object
    const searchResultElement = document.getElementById('searchResultCount')
    const searchIndex = searchResultElement?.getAttribute('data-searchindex') ?? ''
    const resultCount = parseInt(
      searchResultElement?.getAttribute('data-nbhits')?.trim() ?? '-1',
      10
    )

    // early return if we are not on a search page
    if (searchIndex.length === 0) return null

    // grab search data from the URL
    const queryParams = new URLSearchParams(window.location.search)
    const locationFieldValue = queryParams.get('l') ?? ''
    const queryValue = queryParams.get('q') ?? ''
    const facets: { [key: string]: string } = facetKeys.reduce(
      (acc: { [key: string]: string }, key) => {
        const value = queryParams.get(key)
        if (value) {
          acc[key] = value
        }
        return acc
      },
      {}
    )

    const searchContext: SPContext = {
      name: 'search',
      data: {
        search_query: String(queryValue),
        ...(locationFieldValue ? { search_location: locationFieldValue } : {}),
        search_index: searchIndex,
        search_result_count: resultCount,
        search_facets: JSON.stringify(facets),
      },
    }
    return searchContext
  }

  const buildChatContexts = () => {
    const chatContexts: SPContext[] = []
    const searchContext = buildChatSearchContext()

    if (searchContext) {
      chatContexts.push(searchContext)
    }

    return chatContexts
  }

  const handleChatAnalytics = (event: any) => {
    // we need this if statement because this is a global event listener and is constantly runnning
    if (
      event.data === 'uwfChatButtonClicked' ||
      (event.data === 'uwfSetFormSubmitted' && !hasStartedChatRef.current) ||
      event.data === 'uwfRecreateChat'
    ) {
      if (event.data === 'uwfChatButtonClicked') {
        // track button click
        trackSnowplow({
          event: {
            name: 'contact_us_click',
            data: {
              contact_type: 'chat',
            },
          },
          contexts: [
            ...buildChatContexts(),
            {
              name: 'modal',
              data: {
                modal_name: 'Chat with a care specialist at Sharp',
              },
            },
          ],
        })

        // track modal open
        trackSnowplow({
          event: { name: 'modal_open', data: {} },
          contexts: [
            ...buildChatContexts(),
            {
              name: 'modal',
              data: {
                modal_name: 'Chat with a care specialist at Sharp',
              },
            },
          ],
        })
        hasStartedChatRef.current = false
      }

      // track submit chat
      // this message is triggered 3x quickly, useState wasn't fast enough,
      // so we use useRef instead
      if (event.data === 'uwfSetFormSubmitted' && !hasStartedChatRef.current) {
        hasStartedChatRef.current = true

        // set base event data
        let eventData: ContactUsSubmitEvent = {
          contact_type: 'chat',
        }
        // TODO: get form data and add it to the eventData
        trackSnowplow({
          event: { name: 'contact_us_submit', data: eventData },
          contexts: [...buildChatContexts()],
        })
      }

      // track modal_close
      if (event.data === 'uwfRecreateChat') {
        trackSnowplow({
          event: { name: 'modal_close', data: {} },
          contexts: [
            ...buildChatContexts(),
            {
              name: 'modal',
              data: {
                modal_name: 'Chat with a care specialist at Sharp',
              },
            },
          ],
        })
        hasStartedChatRef.current = false
      }
    }
  }

  useEffect(() => {
    if (config?.flags.chat) {
      window.addEventListener('message', handleChatAnalytics)

      // Cleanup function
      return () => {
        window.removeEventListener('message', handleChatAnalytics)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
