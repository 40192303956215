/**
 * use-analytics.ts
 *
 * This module contains functions that send data directly to Snowplow
 *
 * NOTES: (TODO: organize later)
 * Buttons with onClick events should NOT be wrapped in the Analytics component, use trackSnowplow() directly
 * There are two ways to track analytics:
 *   1) use the <Analytics> tag (when? where?) -- preferred
 *   2) use the useAnalytics hook trackSnowplow() function directly (when? where?)
 */
'use client'

import { ClientHintsPlugin } from '@snowplow/browser-plugin-client-hints'
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger'
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking'
import { YouTubeTrackingPlugin } from '@snowplow/browser-plugin-youtube-tracking'
import type { TrackerConfiguration } from '@snowplow/browser-tracker'
import {
  enableActivityTracking,
  newTracker,
  trackPageView as spTrackPageView,
  trackSelfDescribingEvent,
  type BrowserTracker,
} from '@snowplow/browser-tracker'
import { useEffect } from 'react'
import { site } from '@lib/constants'

let tracker: BrowserTracker | null | undefined

/**
 * ===================
 * SNOWPLOW DATA
 * If anything in Snowplow changes (data structures, schema URLs), update the corresponding items below
 * ===================
 */

// SNOWPLOW SCHEMA URL MAPPINGS
export const eventSchemaMap: Map<SPEvent['name'], string> = new Map([
  ['book_appt_click', 'iglu:com-sharp/book_appt_click/jsonschema/2-0-0'],
  ['book_appt_submit', 'iglu:com-sharp/book_appt_submit/jsonschema/1-0-0'],
  ['component_click', 'iglu:com-sharp/component_click/jsonschema/1-0-0'],
  ['component_collapse', 'iglu:com-sharp/component_collapse/jsonschema/1-0-0'],
  ['component_expand', 'iglu:com-sharp/component_expand/jsonschema/1-0-0'],
  ['component_scroll', 'iglu:com-sharp/component_scroll/jsonschema/1-0-0'],
  ['contact_us_click', 'iglu:com-sharp/contact_us_click/jsonschema/1-0-0'],
  ['contact_us_submit', 'iglu:com-sharp/contact_us_submit/jsonschema/2-0-0'],
  ['modal_close', 'iglu:com-sharp/modal_close/jsonschema/1-0-0'],
  ['modal_open', 'iglu:com-sharp/modal_open/jsonschema/1-0-0'],
  ['navigation_click', 'iglu:com-sharp/navigation_click/jsonschema/2-0-0'],
  ['newsletter_submit', 'iglu:com-sharp/newsletter_submit/jsonschema/1-0-0'],
  ['search_query', 'iglu:com-sharp/search_query/jsonschema/1-0-0'],
])

export const contextSchemaMap: Map<SPContext['name'], string> = new Map([
  ['component', 'iglu:com-sharp/component/jsonschema/2-0-0'],
  ['content', 'iglu:com-sharp/content/jsonschema/3-0-1'],
  ['modal', 'iglu:com-sharp/modal/jsonschema/1-0-0'],
  ['page', 'iglu:com-sharp/page/jsonschema/1-0-3'],
  ['person', 'iglu:com-sharp/person/jsonschema/1-0-0'],
  ['place', 'iglu:com-sharp/place/jsonschema/1-0-1'],
  ['provider', 'iglu:com-sharp/provider/jsonschema/1-0-1'],
  ['section', 'iglu:com-sharp/section/jsonschema/2-1-0'],
  ['search', 'iglu:com-sharp/search/jsonschema/3-2-0'],
])

// MAP SNOWPLOW EVENT NAMES TO TYPES
// NOTE: several events have no data of their own, they can have appended contexts only
export type SPEvent =
  | { name: 'book_appt_click'; data: BookApptClickEvent }
  | { name: 'book_appt_submit'; data: BookApptSubmitEvent }
  | { name: 'component_click'; data: {} }
  | { name: 'component_expand'; data: {} }
  | { name: 'component_collapse'; data: {} }
  | { name: 'component_scroll'; data: {} }
  | { name: 'contact_us_click'; data: ContactUsClickEvent }
  | { name: 'contact_us_submit'; data: ContactUsSubmitEvent }
  | { name: 'modal_close'; data: {} }
  | { name: 'modal_open'; data: {} }
  | { name: 'navigation_click'; data: NavigationClickEvent }
  | { name: 'newsletter_submit'; data: {} }
  | { name: 'search_query'; data: SearchQueryEvent }
  | { name: 'view'; data: {} } // this is an automatic snowplow event that we are only sending contexts to

// MAP SNOWPLOW ENTITY NAMES TO TYPES
export type SPContext =
  | { name: 'content'; data: ContentContext }
  | { name: 'component'; data: ComponentContext }
  | { name: 'modal'; data: ModalContext }
  | { name: 'page'; data: PageContext }
  | { name: 'person'; data: PersonContext }
  | { name: 'place'; data: PlaceContext }
  | { name: 'provider'; data: ProviderContext }
  | { name: 'section'; data: SectionContext }
  | { name: 'search'; data: SearchContext }

// SNOWPLOW TYPES
export type PageContext = {
  page_type?: string | null
  page_entry_id?: string | null
  language?: string | null
  tags?: string[] | null
  subsite?: 'sharp' | 'give' | 'envision' | 'scmg' | 'providers'
  slug?: string | null
}
export type PersonContext = {
  person_entry_id: string
  person_name?: string | null
}
export type PlaceContext = {
  place_id: number
  place_name: string | null
}
export type ProviderContext = {
  provider_id: number
  provider_name: string | null
}
export type NavigationClickEvent = {
  navigation_tree: string
  navigation_level?: number | null
  navigation_subject: string
  navigation_url?: string | null
}
export type SectionContext = {
  section_name: 'header' | 'footer' | 'subnav' | 'breadcrumb' | 'body' | 'menu_drawer' | 'search'
}
export type ModalContext = {
  modal_name: string | null
}
export type SearchQueryEvent = {
  search_query_trigger?: 'onKeyUp' | 'onSubmit'
}
export type SearchContext = {
  search_query: string
  search_location?: string | null
  search_index: string
  search_result_count: number
  search_facets?: string | null
}
export type ComponentContext = {
  component_text: string | null
  /** Include the full URL with protocol (http/https) and domain. */
  component_url?: string | null
}
export type ContactUsClickEvent = {
  contact_type: 'contact' | 'chat' | 'call' | 'directory'
  displayed_hours?: string | null
}
export type ContactUsSubmitEvent = {
  contact_type: 'email' | 'chat'
  contact_topic?:
    | 'Billing'
    | 'Careers'
    | 'Classes'
    | 'Find a doctor'
    | 'FollowMyHealth'
    | 'Feedback about my experience'
    | 'Other'
    | 'Same-day care'
    | 'Classes and events'
    | 'Finding a doctor'
    | 'Location information'
    | 'Verify insurance'
    | 'Sharp app and account'
    | null
  contact_location?:
    | 'Sharp Chula Vista'
    | 'Sharp Community Medical Group'
    | 'Sharp Coronado'
    | 'Sharp Grossmont'
    | 'Sharp Mary Birch'
    | 'Sharp Memorial'
    | 'Sharp Rees-Stealy'
    | 'Other'
    | null
}
export type BookApptClickEvent = {
  appointment_method: 'Online booking' | 'Call to schedule'
}
export type BookApptSubmitEvent = {
  new_patient: boolean
}

// used to track CMS content data
export type ContentContext = {
  content_type: string | null // sys.contentType.sys.id
  content_entry_id?: string | null // sys.contentType.sys.id
  content_internal_name?: string | null
  content_level?: 'block' | 'item'
}

/**
 * ===================
 * END SNOWPLOW DATA
 * ===================
 */

/**
 * Snowplow client initialization, caches client/tracker in module scope.
 */
const init = () => {
  const isProd = window.location.hostname === site.hostname
  const collectorProd = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL_PROD ?? ''
  const collectorNonProd = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL_NONPROD ?? ''
  const collectorUrl = isProd ? collectorProd : collectorNonProd
  const isDebug = !isProd

  // Initialize the Snowplow tracker
  const trackerConfig: TrackerConfiguration = {
    appId: 'sharp-com',
    cookieSameSite: 'Lax',
    cookieSecure: true,
    keepalive: true,
    plugins: [ClientHintsPlugin(), LinkClickTrackingPlugin(), YouTubeTrackingPlugin()],
  }

  // Add the debugger Plugin if explicitly requested
  if (isDebug) {
    trackerConfig?.plugins?.push(DebuggerPlugin())
  }

  tracker = newTracker('sp', collectorUrl, trackerConfig)

  if (!tracker) {
    console.error('Snowplow Tracker is not initialized')
  }

  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10,
  })

  enableLinkClickTracking({
    pseudoClicks: true,
    trackContent: true,
    options: {
      allowlist: ['snowplow-link-click', 'tracked'],
    },
  })
}

const isInitialized = () => !!tracker

type TrackPageViewProps = {
  contexts?: SPContext[]
}
const trackPageView = ({ contexts = [] }: TrackPageViewProps) => {
  spTrackPageView({
    context: contexts.map(({ name, data }) => {
      const contextSchema = contextSchemaMap.get(name)

      if (!contextSchema) {
        console.error('use-analytics > trackPageView(): Context Schema not found:', name)
      }

      return {
        schema: contextSchema ?? '',
        data,
      }
    }),
  })
}

export type TrackProps = {
  event: SPEvent
  contexts?: SPContext[]
}

/**
 * Tracks a self-describing event using the provided event and contexts.
 *
 * @param {Object} params - The parameters for tracking the event.
 * @param {SPEvent} params.event - The event to be tracked.
 * @param {SPContext[]} [params.contexts=[]] - The contexts to be included with the event.
 *
 * @example
 * // Example usage
 * import type { SPEvent, SPContext } from '@hooks/use-analytics'
 * const { trackSnowplow } = useAnalytics()
 *
 * const event: SPEvent = {
 *   name: 'component_click',
 *   data: {
 *     component_id: 'button-123',
 *     component_name: 'Submit Button',
 *   },
 * };
 *
 * const contexts: SPContext[] = [
 *   {
 *     name: 'user_context',
 *     data: {
 *       user_id: 'user-456',
 *       user_role: 'admin',
 *     },
 *   },
 * ];
 *
 * trackSnowplow({ event, contexts });
 */
const trackSnowplow = ({ event, contexts = [] }: TrackProps) => {
  const { name, data } = event
  const eventSchema = eventSchemaMap.get(name)

  if (!eventSchema) {
    console.error('use-analytics > trackSnowplow(): Event Schema not found:', name)
  }

  const selfDescribingEvent = {
    event: {
      schema: eventSchema ?? '',
      data,
    },
    context: contexts.map(({ name, data }) => {
      const contextSchema = contextSchemaMap.get(name)

      if (!contextSchema) {
        console.error('use-analytics > trackSnowplow(): Context Schema not found:', name)
      }

      return {
        schema: contextSchema ?? '',
        data,
      }
    }),
  }

  trackSelfDescribingEvent(selfDescribingEvent)
}

const useAnalytics = () => {
  useEffect(() => {
    if (!isInitialized()) init()
  })
  return {
    isInitialized,
    trackSnowplow,
    trackPageView,
  }
}

export default useAnalytics
